








































































.notice {
  margin-top: 60px;
  padding-bottom: 190px;
}
h2 {
  margin: 0 auto 50px;
  text-align: center;
  line-height: 37px;
  color: #333;
  font-size: 26px;
  font-weight: 500;
}
h3 {
  margin-top: 30px;
  margin-bottom: 12px;
  line-height: 30px;
  font-size: 22px;
  font-weight: 500;
  color: #333;
}
ul li {
  line-height: 32px;
  font-size: 16px;
  color: #333;
}
.btn {
  width: 300px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  margin: 60px auto 0;
  background: #ff6000;
  border-radius: 2px;
  font-size: 16px;
  color: #fff;
  cursor: pointer;
  &.disable {
    background: #f7f7f7;
    border: 1px solid #ededed;
    color: #bbb;
  }
}
